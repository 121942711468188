<template>
    <div class="page-content">
        <div class="title-wrap" data-aos="fade-down">
            <div class="wrapper">
                <div class="top">
                    <div class="left">
                        <div class="launch">
                            <p>COMING SOON</p>
                        </div>
                        <div class="text-box">
                            <h1>Discover and track your anime.</h1>
                            <h1>In an old way. On a new platform.</h1>
                        </div>
                        <div class="dictionary">
                            <p><strong>Zenbu</strong> (全部) - <i>jap. noun</i> <strong>everything</strong></p>
                        </div>
                        <div class="text-box wrap">
                            <p>Discover new anime and keep track of old shows. Find your community or make one. 
                            Be in the spotlight or track privately. You can do <i>everything</i>. On <i>Zenbu.</i></p>
                        </div>
                    </div>
                    <div class="right">
                        <div class="image-container">
                            <img class="part-zenbu" src="../assets/SVG/zenbu.svg">
                            <img class="part-platform" src="../assets/SVG/platform.svg">
                            <img class="part-clouds" src="../assets/SVG/clouds.svg">
                            <img class="part-shadow" src="../assets/SVG/shadow.svg">
                            <img class="part-flying-clouds" src="../assets/SVG/flying-clouds.svg">
                        </div>
                    </div>
                </div>
                <a class="wip bottom" href="https://github.com/zenbu-moe">
                    <svg-icon style="color: white;" icon="brands/github"/><p>Visit our GitHub</p>
                </a>
            </div>
            <img class="site-pictures" src="../assets/zenbu-dark.png">
        </div>
        <div class="showcase" id="showcase">
            <div class="showcase-item reverse" data-aos="fade-left">
                <div>
                    <video loop muted autoplay class="image">
                        <source src="../assets/layout-dark.mp4">
                    </video>
                </div>
                <div class="info">
                    <h2>Modern design. Simple and responsive layout.</h2>
                    <p>
                        Zenbu is a modern platform, so, naturally, it comes with a modern look
                        and feel. It is easy to navigate, and just makes sense. Zenbu also offers
                        a mobile site that makes you want to keep using it.
                    </p>
                </div>
            </div>
            <hr>
            <div class="showcase-item" data-aos="fade-right">
                <div>
                    <video loop muted autoplay class="image">
                        <source src="../assets/profile-dark.mp4">
                    </video>
                </div>
                 <div class="info">
                    <h2>Make your page your own.</h2>
                    <p>
                        User pages feature a fully customizable grid layout with an option of disabling absolutely
                        everything (including even the social feed), allowing users to tweak their experience
                        to their own tastes. 
                    </p>
                </div>
            </div>
            <hr>
            <!-- <div class="showcase-item">
                <div class="info">
                    <h2>Join a community. Or create your own.</h2>
                    <p>
                        Forums are great, but we believe that groups (or clubs, or communities) are better. Engage
                        with an established group, or create your own. Big or small - no difference, as long as you are comfortable.
                        Meet new friends, and expand your horizons.
                    </p>
                </div>
                <div class="image">
                    <img class="image" src="../assets/community.png">
                </div>
            </div> -->
            <a class="wip top-wip" href="https://github.com/zenbu-moe">
                <svg-icon icon="brands/github"/><p>More features in development</p>
            </a>
        </div>
    </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
    name: 'pageContent',
    created() {
        AOS.init();
    }
}
</script>

<style scoped>
    h1 {
        margin: 0;
        font-family: "Lexend";
    }

    .site-pictures {
        background-size: cover;
        border-radius: 15px;
        background-position-x: 50%;
        width: 70%;
        margin: auto;
        border: 2px solid rgb(var(--color-gray))
    }

    hr {
        width: 1280px;
        opacity: 0.5
    }

    p, h1, h2, h3, h4 {
        color: rgb(var(--color-text))
    }

    .title-wrap p, .title-wrap h1, .title-wrap h2, .title-wrap h3, .title-wrap h4 {
        color: white;
    }

    .showcase-item {
        width: 1280px;
        display: flex;
        margin: 100px auto;
        justify-content: space-between;
        align-items: center;
    }

    .reverse {
        flex-direction: row-reverse;
    }

    .info {
        width: 600px;
        line-height: 150%;
    }

    .image {
        width: 600px;
        /* height: 320px; */
        /* background-color: rgb(var(--color-gray-dark)); */
        border-radius: 10px;
        background-size: cover;
        background-position-x: 50%;
        border: 1px solid rgb(var(--color-gray))
    }

    /* .img1 {
        background-image: url("../assets/layout.gif");
    } */

    .title-wrap {
        display: flex;
        background-color: rgb(var(--color-background-dark));
        flex-direction: column;
        align-items: center;
        transition: 1s;
        padding-bottom: 50px;
    }

    .wrapper {
        background-color: rgb(var(--color-navigation));
        transition: 1s;
        width: 100%;
        margin: 50px auto 50px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;
    }

    .dictionary {
        border-top: 1px solid rgba(255,255,255, 0.5);
        border-bottom: 1px solid rgba(255,255,255, 0.5);
    }

    .top {
        display: grid;
        grid-template-columns: 50% 50%;
        justify-content: center;
        width: 1280px;
        margin: 25px auto 50px auto;
    }

    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start
    }

    .right {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .image-container {
        position: relative;
        display: flex;
        justify-content: center;
        width: 90%;
    }

    @keyframes zenbu-art {
        0% {left: -100px}
        50% {left: -50px}
        100% {left: -100x}
    }

    .part-zenbu {
        width: 55%;
        margin-left: 10%;
        z-index: 3;
        animation: shakeY 30s;
        animation-iteration-count: infinite;
    }

    .part-platform {
        width: 90%;
        position: absolute;
        bottom: 65px;
    }

    .part-shadow {
        position: absolute;
        bottom: 95px;
        width: 33%;
        margin-left: -3%;
        animation: flash 12s;
        animation-iteration-count: infinite;
    }

    .part-clouds {
        width: 100%;
        position: absolute;
        bottom: 58px;
    }

    .part-flying-clouds {
        position: absolute;
        top: 100px;
        width: 90%;
        animation: shakeX 30s;
        animation-iteration-count: infinite;
    }

    .text-box {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .wrap {
        max-width: 500px;
    }

    .text-box h1 {
        font-size: 2.5rem;
        margin: 15px 0px 0px 0px;
        text-align: left;
    }

    .text-box p {
        font-size: 1.2rem;
        margin-top: 35px;
        line-height: 200%;
        text-align: left;
    }

    .wip {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        padding: 0px 20px;
        border-radius: 40px;
        background-color: rgba(205, 174, 240, 0.377);
        cursor: pointer;
        transition: 0.5s;
    }

    .wip:hover {
        background-color: rgba(205, 174, 240, 0.842);
        transition: 0.2s;
    }

    .wip svg.icon {
        margin-right: 20px;
        font-size: 1.6rem;
    }

    .top-wip {
        margin: 50px auto 20px auto;
        width: 350px
    }

    .bottom-wip {
        margin: 20px auto 50px auto;
    }

    @media screen and (max-width: 1280px) {
        .top {
            grid-template-columns: 80%;
            width: 100%;
        }

        .right {
            position: relative;
            width: 100%;
            align-items: center;
            display: flex;
        }

        .left {
            width: 100%;
            margin: 0 auto;
            align-items: center;
        }

        .part-platform {
            width: 90%;
            position: absolute;
            bottom: 3%;
        }

        .part-shadow {
            position: absolute;
            bottom: 11.3%;
            width: 33%;
            margin-left: -5%;
            animation: flash 12s;
            animation-iteration-count: infinite;
        }

        .part-clouds {
            width: 100%;
            position: absolute;
            bottom: 1%;
        }

        .part-flying-clouds {
            position: absolute;
            top: 20%;
            width: 80%;
            animation: shakeX 30s;
            animation-iteration-count: infinite;
        }

        .text-box h1 {
            font-size: 2rem;
            margin: 15px 0px 0px 0px;
            text-align: left;
        }

        .text-box p {
            font-size: 0.9rem;
            margin-top: 35px;
            line-height: 200%;
            text-align: left;
        }

        .showcase-item {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin: 30px auto;
        }

        .reverse {
            flex-direction: column;
        }

        .info {
            width: 80%;
            margin: 20px auto;
        }

        .image {
            width: 80%;
            margin: auto auto;
            display: flex;
            align-items: center;
        }

        .site-pictures {
            background-size: cover;
            border-radius: 15px;
            background-position-x: 50%;
            width: 90%;
            margin: auto;
            border: 2px solid rgb(var(--color-gray))
        }

        .top-wip {
            margin: 50px auto 20px auto;
            width: 260px;
        }

        .top-wip p {
            font-size: 0.8rem;
        }
    }
</style>